import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "src/utils/toast";
import { useTranslation } from "react-i18next";
import useUser from "src/swr/use-user";
import useAuth from "../../hooks/useAuth";
import AvatarSkeleton from "../skeleton/AvatarSkeleton";
import { BsCopy, BsShieldLockFill } from "react-icons/bs";

import { AiOutlineLogout } from "react-icons/ai";
import { MdManageAccounts } from "react-icons/md";
import { MdWorkHistory } from "react-icons/md";
import { MdOutlineWallet } from "react-icons/md";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";
import SoundButton from "./overview/SoundButton";
import LayoutContext from "src/contexts/LayoutContext";
import { apiPost } from "src/services/apiService";
import { isInitialized } from "@sentry/react";
import TwoStepVerificationModal from "../modals/TwoStepVerificationModal";
import { isMobile } from "react-device-detect";

const menuStyle = {
  "& .MuiList-root": {
    width: "100%",
    minWidth: "300px",
    padding: "1.5rem 1.5rem .75rem",
    border: "2px solid #04141d",
    background: "rgb(0 9 14 / 97%)",
    borderRadius: "8px",
    backdropFilter: "blur(10px)",
    marginTop: "20px",
  },
  "& .header_info, & .footer_info": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .header_info .copy_id": {
    color: "#AAA",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    cursor: "pointer",

    "&:hover": {
      color: "#01DB97",
      fill: "#01DB97",
    },
  },
  "& .footer_info": {
    paddingTop: "0.5rem",
  },
  "& .middle_info": {
    color: "#EEE",
    padding: "1rem 0",
    display: "flex",
    justifyContext: "center",
    gap: "0.5rem",
  },
  "& .middle_info .middle_info_flex": {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  "& .middle_info .list_item": {
    gap: "0.5rem",
    borderRadius: "8px",
    backgroundColor: "#0c161a",
    fontSize: "14px",
    display: "flex",
    padding: "1rem 2rem",
    alignItems: "center",
    justifyContent: "flex-start",
    fontWeight: "500",
    paddingLeft: isMobile ? "4.5rem" : "2rem",

    "& svg": {
      fill: "#01DB97",
    },

    "&: hover": {
      background: "#111e23",
      color: "#FFF",
    },
  },
  "& .footer_info li": {
    padding: "1rem 0.5rem",
    gap: "0.5rem",

    "&: hover": {
      background: "transparent",
      color: "#01DB97",
    },
    "&.logout:hover": {
      background: "transparent",
      color: "#FF025C",
    },
  },
};

const button2FAStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
  padding: "0.5rem 0",
  borderRadius: "8px",
  "&.active_2fa": {
    backgroundColor: "#19553d",
    "& > svg": {
      fill: "#01DB97",
    },
    "&:hover": {
      backgroundColor: "#0f744e",
    },
  },
  "&.desactive_2fa": {
    backgroundColor: "rgb(143 26 68 / 25%)",
    "& > svg": {
      fill: "#ff025c",
    },
    "&:hover": {
      backgroundColor: "rgb(143 26 68 / 50%)",
    },
  },
};

const AccountMenu: (props) => JSX.Element = (props: {
  openTemporaryModalFunction: () => void;
}) => {
  const { t } = useTranslation("dashboard");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { logout } = useAuth();
  const { user, mutate, loading: loadingUser } = useUser();
  const navigate = useNavigate();
  const [copiedLink, setCopiedLink] = useState<string | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { setModalTwoStepVerificationModal } = useContext(LayoutContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
      notifyError("Unable to logout");
    }
  };

  const handleCopy = (e, text: string) => {
    e.stopPropagation();
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopiedLink(text);
          setTimeout(() => {
            setCopiedLink(null);
          }, 1500);
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  const handleDisable2fa = async () => {
    try {
      await apiPost("users/disable2fa", {});
      await isInitialized();
      notifySuccess("Verificação em duas etapas desativada com sucesso");
      mutate();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Tooltip title="Account settings">
        <Box sx={{ alignSelf: "center" }}>
          {loadingUser ? (
            <AvatarSkeleton />
          ) : (
            <IconButton onClick={handleClick} size="small" sx={{ padding: 0 }}>
              <Avatar src={user.avatar} sx={{ width: 40, height: 40 }} />
            </IconButton>
          )}
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={menuStyle}
        elevation={0}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box className="header_info">
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "flex-start" : "center",
              justifyContent: isMobile ? "initial" : "space-between",
              width: isMobile ? "initial" : "100%",
            }}
          >
            <Typography fontSize="1rem">{user?.name}</Typography>
            <Typography
              variant="body2"
              className="copy_id"
              onClick={(e) => handleCopy(e, user?.publicId)}
              color={copiedLink !== null && "#01DB97 !important"}
            >
              ID: {copiedLink !== null ? `${t("copied")}!` : user?.publicId}{" "}
              <BsCopy size={12} />
            </Typography>
          </Box>
          {isMobile && <SoundButton />}
        </Box>

        <Box
          className="middle_info"
          sx={{
            flexDirection: isMobile ? "column" : "row",
            "& li": {
              padding: isMobile ? "0.5rem 1rem" : "1rem 2rem",
              justifyContent: "center",
            },
          }}
        >
          <Box className="middle_info_flex">
            <Button
              className="list_item"
              onClick={() => navigate("/dashboard/profile?tab=profile")}
            >
              <MdManageAccounts size={24} color="#06865e" />
              {t("my_account")}
            </Button>
            <Button
              className="list_item"
              onClick={() => navigate("/dashboard/history/operations")}
            >
              <MdWorkHistory size={24} color="#06865e" />
              {t("historic")}
            </Button>
          </Box>
          <Box className="middle_info_flex">
            <Button
              className="list_item"
              onClick={() => navigate("/dashboard/profile?tab=deposit")}
            >
              <MdOutlineWallet size={24} color="#06865e" />
              {t("deposit")}
            </Button>
            <Button
              className="list_item"
              onClick={() => navigate("/dashboard/profile?tab=to_withdraw")}
            >
              <FaMoneyCheckDollar size={24} color="#06865e" />
              {t("to_withdraw")}
            </Button>
          </Box>
        </Box>

        <Box>
          {!user?.using2fa ? (
            <MenuItem
              onClick={() => setModalTwoStepVerificationModal(true)}
              sx={button2FAStyle}
              className="active_2fa"
            >
              <BsShieldLockFill size={24} color="#FFF" />
              {t("enable_2FA")}
            </MenuItem>
          ) : (
            <MenuItem
              onClick={handleDisable2fa}
              sx={button2FAStyle}
              className="desactive_2fa"
            >
              <BsShieldLockFill size={24} color="#FFF" />
              {t("disable_2FA")}
            </MenuItem>
          )}
        </Box>

        <Box className="footer_info">
          <MenuItem
            hidden={true}
            onClick={() => navigate("/dashboard/profile?tab=support")}
          >
            <BiSupport size={24} color="#01DB97" />
            {t("support")}
          </MenuItem>
          <MenuItem onClick={handleLogout} className="logout">
            <AiOutlineLogout size={24} color="#FF025C" />
            {t("log_out")}
          </MenuItem>
        </Box>
      </Menu>

      <TwoStepVerificationModal />
    </>
  );
};

export default AccountMenu;
