import {
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import NewLayoutImage from "../../../assets/images/new_layout.png";
const NewLayoutSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("landing");

  const containerStyle = {
    position: "relative",
    py: { xs: 4, md: 4 },

    "& .greenText": {
      color: "#01db97",
    },
  };

  return (
    <>
      <Container sx={containerStyle}>
        <Grid
          container
          position={"relative"}
          spacing={4}
          sx={{ py: { xs: 2, md: 10 } }}
        >
          <Grid item xs={12} md={7}>
            <img
              src={NewLayoutImage}
              alt="Imagem do novo layout de operações de trading"
              style={{
                width: isMobile ? "100%" : "750px",
                position: isMobile ? "initial" : "absolute",
                top: "-72px",
                left: "-80px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack
              spacing={2}
              sx={{ mt: { xs: 0, md: 3 }, mb: { xs: 3, md: 5 } }}
            >
              <Typography
                fontFamily="Epilogue"
                align={isMobile ? "center" : "left"}
                fontSize={{
                  xs: 40,
                  md: 48,
                }}
                fontWeight={600}
                lineHeight={1.15}
              >
                {t("new_layout_of")}{" "}
                <span className="greenText"> {t("operations")} </span>
              </Typography>
              <Typography fontSize={16} align={isMobile ? "center" : "left"}>
                {t("new_layout_description")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default NewLayoutSection;
