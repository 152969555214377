import { Box, Stack, Typography } from "@mui/material";

const HowItWorksStep = ({ icon, title, description, isMobile }) => {
  return (
    <Stack
      spacing={2}
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box className="icon_box">{icon}</Box>
      <Typography fontFamily="Epilogue" fontSize={20} fontWeight={500}>
        {title}
      </Typography>
      <Typography
        fontFamily="Epilogue"
        fontSize={14}
        width={isMobile ? "100%" : "70%"}
        textAlign="center"
      >
        {description}
      </Typography>
    </Stack>
  );
};

export default HowItWorksStep;
