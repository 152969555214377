import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import RetractionIcon from "../../assets/images/retraction_icon.png";
import HowItWorks from "./components/HowItWorks";
import NewLayoutSection from "./components/NewLayoutSection";
import TestSection from "./components/TestSection";
import { LandingHeader } from "./components/LandingHeader";
import LandingFooter from "./components/LandingFooter";
import HomeLanding from "./components/HomeLanding";
import { useEffect, useState } from "react";

const greenLight = {
  width: "250px",
  height: "250px",
  position: "absolute",
  top: "320px",
  left: "-80px",
  background:
    "radial-gradient(circle, rgb(0 255 170) 35%, rgb(101 255 191 / 16%) 50% )",
  filter: "blur(135px)",
};

const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const opacityAnimation = {
    opacity: fadeIn ? 1 : 0,
    transition: "opacity 1s ease-in-out 0.75s",
  };

  return (
    <Box
      sx={{
        fontWeight: 400,
        minHeight: "100vh",
        color: "#fff",
        background: "#00060A",
      }}
    >
      <LandingHeader />
      <Box sx={{ position: "relative", overflow: "hidden" }}>
        {!isMobile && (
          <img
            src={RetractionIcon}
            alt=""
            style={{
              width: "500px",
              position: "absolute",
              right: "-172px",
              top: "135px",
              ...opacityAnimation,
            }}
          />
        )}

        <Box component="span" sx={greenLight}></Box>

        <Box id="home-hero">
          <HomeLanding fadeIn={fadeIn} />
        </Box>
        <Box id="how-it-works-section" sx={opacityAnimation}>
          <HowItWorks />
        </Box>
        <Box id="new_layout-section">
          <NewLayoutSection />
        </Box>
        <Box id="test-section">
          <TestSection />
        </Box>
      </Box>
      <LandingFooter />
    </Box>
  );
};

export default LandingPage;
