import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import BannerImage from "../../../assets/images/new_hero.png";
import { CgTimelapse } from "react-icons/cg";
import { MdCandlestickChart } from "react-icons/md";

const HomeLanding = ({ fadeIn }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation("landing");

  const tabStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    borderBottom: "2px solid #1a2323",
    paddingBottom: ".75rem",
    width: "160px",
    color: "#424f4f",

    "&.active": {
      color: "#EEE",
      borderColor: "#01db97",
    },
  };

  return (
    <>
      <Container
        sx={{
          position: "relative",
          py: { xs: 0, md: 4 },
        }}
      >
        <Grid
          container
          position={"relative"}
          spacing={4}
          sx={{ py: { xs: 2, md: 12 } }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              opacity: fadeIn ? 1 : 0,
              transition: "opacity 1s ease-in-out",
            }}
          >
            <Stack
              spacing={2}
              sx={{
                mt: 3,
                mb: { xs: 3, md: 5 },
              }}
            >
              <Stack
                direction="row"
                justifyContent={isMobile ? "center" : "flex-start"}
                pb={2}
              >
                <Box sx={tabStyle} className="active">
                  <CgTimelapse size={16} color="#01DB97" />
                  <Typography>{t("retraction")}</Typography>
                </Box>
                <Box sx={tabStyle}>
                  <MdCandlestickChart size={16} />
                  <Typography>{t("new_options")}</Typography>
                </Box>
              </Stack>
              <Typography
                fontFamily="Epilogue"
                align={isMobile ? "center" : "left"}
                fontSize={{
                  xs: 40,
                  md: 56,
                }}
                fontWeight={700}
                lineHeight={1.15}
              >
                {t("the")}
                <span style={{ color: "#01DB97" }}>
                  {" "}
                  {t("retraction")}{" "}
                </span>{" "}
                {t("is_coming_to_ebinex")}
              </Typography>
              <Typography fontSize={16} align={isMobile ? "center" : "left"}>
                {t("choose_modes_description")}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent={{ xs: "center", md: "start" }}
            >
              <Button
                href="#test-section"
                variant="contained"
                size="large"
                fullWidth
              >
                {t("subscribe")}
              </Button>
            </Stack>
          </Grid>
          <img
            src={BannerImage}
            alt="Gráfico de trade da plataforma Ebinex em um celular"
            style={{
              width: isMobile ? "100%" : "850px",
              position: isMobile ? "initial" : "absolute",
              marginLeft: isMobile ? "20px" : "auto",
              right: "-140px",
              top: "-48px",
              opacity: fadeIn ? 1 : 0,
              transition: "opacity 1s ease-in-out 0.5s",
            }}
          />
        </Grid>
      </Container>
    </>
  );
};

export default HomeLanding;
