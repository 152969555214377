import {
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { FC } from "react";
import { useTranslation } from "react-i18next";
const TestSection: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("landing");

  const containerStyle = {
    position: "relative",
    py: { xs: 4, md: 4 },

    "& .greenText": {
      color: "#01db97",
    },

    "& .MuiInputBase-root::before": {
      border: "none",
    },
  };

  return (
    <>
      <Container sx={containerStyle}>
        <Grid
          container
          position={"relative"}
          spacing={4}
          mt={{ xs: 0, md: 4 }}
          sx={{ py: { xs: 0, md: 12 } }}
          justifyContent={isMobile ? "center" : "space-between"}
        >
          <Grid item xs={12} md={6}>
            <Stack spacing={2} sx={{ mb: { xs: 3, md: 5 } }}>
              <Typography
                fontFamily="Epilogue"
                align={isMobile ? "center" : "left"}
                fontSize={{
                  xs: 38,
                  md: 48,
                }}
                fontWeight={600}
                lineHeight={1.15}
              >
                {t("test")}{" "}
                <span className="greenText"> {t("in_advance")} </span>
              </Typography>
              <Typography fontSize={16} align={isMobile ? "center" : "left"}>
                {t("test_description")}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <form>
              <Stack spacing={2} sx={{ mt: 3, mb: { xs: 3, md: 5 } }}>
                <TextField
                  sx={{ border: "none" }}
                  fullWidth
                  label={t("name")}
                  variant="filled"
                />
                <TextField fullWidth label="E-mail" variant="filled" />
                <TextField fullWidth label={t("number")} variant="filled" />
                <Button variant="contained" type="submit" size="large">
                  {t("subscribe")}
                </Button>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default TestSection;
