import { Box, Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const LandingFooter = () => {
  const { t } = useTranslation("footer");

  return (
    <Box component="footer">
      <Box
        sx={{
          py: 4,
          backgroundColor: "#19191940",
        }}
      >
        <Container>
          <Stack
            alignItems={{ md: "center" }}
            spacing={{ xs: 5, md: 10 }}
            direction={{ xs: "column", md: "row" }}
          >
            <Stack spacing={4}>
              <Typography
                fontSize={14}
                lineHeight={1.7}
                color="#B3B8BB"
                align="justify"
              >
                {t("description")}
              </Typography>
              <Typography fontSize={14} fontWeight="500">
                {t("all_rights_reserved")}
              </Typography>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingFooter;
