import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FaRegClock } from "react-icons/fa";
import { MdCandlestickChart, MdOutlineAdsClick } from "react-icons/md";
import { GiTwoCoins } from "react-icons/gi";
import HowItWorksStep from "./HowItWorksStep";

const HowItWorks: FC = () => {
  const { t } = useTranslation("landing");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const stepsStyle = {
    border: "1px solid #084f39",
    borderRadius: "8px",
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "center",
    gap: "1.5rem",
    padding: "2rem",
    background:
      "linear-gradient(147deg, rgb(19 153 111 / 8%) 0%, rgb(3 20 15 / 7%) 80%, rgb(19 153 111 / 48%) 100%)",

    "& .icon_box": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#01160f",
      width: "56px",
      height: "56px",
      borderRadius: "50%",
    },
  };

  const steps = [
    {
      id: 0,
      icon: <FaRegClock size={32} color="#01db97" />,
      title: t("more_interval"),
      description: t("more_interval_description"),
    },
    {
      id: 1,
      icon: <MdOutlineAdsClick size={32} color="#01db97" />,
      title: t("agility"),
      description: t("agility_description"),
    },
    {
      id: 2,
      icon: <MdCandlestickChart size={32} color="#01db97" />,
      title: t("instantaneous"),
      description: t("instantaneos_description"),
    },
    {
      id: 3,
      icon: <GiTwoCoins size={32} color="#01db97" />,
      title: `+${t("15_assets")}`,
      description: t("15_assets_description"),
    },
  ];

  return (
    <Container sx={{ position: "relative", py: { xs: 0, md: 10 } }}>
      <Stack spacing={4}>
        <Typography
          textAlign={isMobile ? "center" : "left"}
          fontFamily="Epilogue"
          fontSize={32}
          fontWeight={500}
        >
          {t("how_it_works")}
        </Typography>
        <Box sx={stepsStyle}>
          {steps.map((step) => (
            <HowItWorksStep
              icon={step.icon}
              title={step.title}
              description={step.description}
              isMobile={isMobile}
            />
          ))}
        </Box>
      </Stack>
    </Container>
  );
};

export default HowItWorks;
