"use client";

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR } from "date-fns/locale";
import { useContext, useEffect, useState } from "react";

import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { FaCircleMinus, FaCirclePlus } from "react-icons/fa6";
import { InputAdornmentIcon } from "src/components/InputAdornmentIcon";
import ApiContext from "src/contexts/ApiContext";
import { useCountdownContext } from "src/contexts/CountdownContext";
import { getBrokerNowTime } from "../TVChartContainer/datafeed";

const ADJUSTMENT_MS = 90000;

export default function TimePicker() {
  const { t } = useTranslation("dashboard");
  const { count } = useCountdownContext();
  const [inputBaseTime, setInputBaseTime] = useState<Date>(
    new Date(new Date().getTime() + ADJUSTMENT_MS)
  );
  const [additionalMinutes, setAdditionalMinutes] = useState<number>(0);
  const { updateDatatime } = useContext(ApiContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const now = getBrokerNowTime();

    if (additionalMinutes === 0) {
      setInputBaseTime(new Date(now + ADJUSTMENT_MS));
    } else {
      const adjustedTime = new Date(
        inputBaseTime.getTime() + additionalMinutes * 60000
      );
      if (adjustedTime.getTime() <= now + ADJUSTMENT_MS) {
        setAdditionalMinutes(0);
        setInputBaseTime(new Date(now + ADJUSTMENT_MS));
      }
    }
  }, [count]);

  useEffect(() => {
    updateDatatime(getTimestamp());
  }, [inputBaseTime, additionalMinutes]);

  const getAdjustedTime = (): Date => {
    return new Date(inputBaseTime.getTime() + additionalMinutes * 60000);
  };

  const formatTime = (date: Date): string => {
    return date.toTimeString().slice(0, 5);
  };

  const incrementMinutes = () => {
    setAdditionalMinutes((prev) => Math.min(prev + 1, 59));
  };

  const decrementMinutes = () => {
    setAdditionalMinutes((prev) => Math.max(prev - 1, 0));
  };

  const getTimestamp = (): number => {
    const timestamp = getAdjustedTime().getTime();
    return Math.floor(timestamp / 60000) * 60000;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <TextField
        size={isMobile ? "small" : "medium"}
        sx={{
          "& .MuiInputBase-root input": {
            fontSize: isMobile ? "1rem" : "1.25rem",
            padding: isMobile ? "8.5px 0" : "0.850rem 0",
          },
          "& .MuiIconButton-root:hover": {
            background: "transparent",
          },
        }}
        value={formatTime(getAdjustedTime())}
        label={t("time")}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornmentIcon
              icon={FaCircleMinus}
              onClick={decrementMinutes}
            />
          ),
          endAdornment: (
            <InputAdornmentIcon
              onClick={incrementMinutes}
              position="end"
              icon={FaCirclePlus}
            />
          ),
          inputProps: {
            readOnly: true,
          },
        }}
      />
      <Typography height={16} color="#919eab" variant="caption">
        {t("interval")}: 0 - 59 m
      </Typography>
    </LocalizationProvider>
  );
}
