import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Logo from "src/components/Logo";
import LanguagePopover from "src/components/dashboard/LanguagePopover";

export const LandingHeader = () => {
  const { t } = useTranslation(["landing"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ borderBottom: "1px solid #0f1414", padding: "0.5rem 0" }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 9,
          backgroundColor: "#00060A",
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            height: 72,
          }}
        >
          <Link component={RouterLink} to="/" sx={{ m: isMobile ? "auto" : 0 }}>
            <Logo sx={{ height: 32 }} />
          </Link>
          {!isMobile && (
            <Stack
              direction="row"
              spacing={isMobile ? 1 : 2}
              sx={{ ml: "auto" }}
            >
              <LanguagePopover />
              <Button
                variant="contained"
                size={isMobile ? "large" : "large"}
                href="#test-section"
              >
                {t("subscribe")}
              </Button>
            </Stack>
          )}
        </Container>
      </Box>
    </Box>
  );
};
