import { Box, Button, Modal, Typography } from "@mui/material";
import X from "src/icons/X";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const HistoryCandlesModal = ({ open, handleClose, title, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={"#fff"}
          >
            {title}
          </Typography>
          <Button onClick={handleClose} sx={{ mt: 2 }}>
            <X />
          </Button>
        </Box>
        <Box sx={{ mt: 2, height: "calc(100% - 56px)" }}>{children}</Box>
      </Box>
    </Modal>
  );
};

export default HistoryCandlesModal;
